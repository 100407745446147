<template>
    <div class="login-container">
        <el-form
            autoComplete="on"
            :class="{ mobile: isDeviceMobile }"
            :model="loginForm"
            :rules="loginRules"
            ref="loginForm"
            label-position="left"
            label-width="0px"
            class="card-box login-form"
        >
            <div class="form-wrapper">
                <div class="form-contents">
                    <div class="lang-select">
                        <lang-select></lang-select>
                    </div>
                    <div class="login-logo title-no-border">
                        <img :src="logoPath" alt="Cel.ro Marketplace" />
                        <div class="logoText-big"><span class="primary">market</span>place</div>
                        <div class="logoText-small">marketplace.cel.ro</div>
                    </div>
                    <el-form-item prop="username">
                        <span class="svg-container svg-container_login">
                            <svg-icon icon-class="login2" />
                        </span>
                        <el-input
                            name="username"
                            size="default"
                            type="text"
                            @keypress.enter.native="handleLogin"
                            v-model="loginForm.username"
                            autoComplete="on"
                            :placeholder="$t('login.user')"
                        />
                    </el-form-item>
                    <el-form-item prop="password" class="login-form-pass">
                        <span class="svg-container">
                            <svg-icon icon-class="unlocked" />
                        </span>
                        <el-input
                            size="default"
                            name="password"
                            :type="pwdType"
                            @keypress.enter.native="handleLogin"
                            v-model="loginForm.password"
                            autoComplete="on"
                            :placeholder="$t('login.password')"
                        >
                        </el-input>
                        <span class="show-pwd" @click="showPwd" :title="$t('login.show-pass')">
                            <!-- <i class="el-icon-view"></i> -->
                            <svg-icon icon-class="eye2" />
                        </span>
                    </el-form-item>
                    <el-checkbox class="rememberMe" v-model="loginForm.remember"
                        >{{ $t('login.remember-me') }}
                    </el-checkbox>
                    <el-form-item>
                        <el-button
                            size="default"
                            type="primary"
                            style="width:100%;height:49px;"
                            :loading="loading"
                            @click.native.prevent="handleLogin"
                        >
                            {{ $t('login.login') }}
                        </el-button>
                    </el-form-item>
                    <div class="forgotPassword">
                        <router-link to="/forgot-password">
                            {{ $t('login.forgotpass') }}
                        </router-link>
                    </div>
                    <div class="noAccountLogin">
                        <router-link to="/">
                            {{ $t('login.register') }}
                        </router-link>
                    </div>
                </div>
                <div class="description" v-if="!isDeviceMobile">
                    <div class="desc-title">{{ $t('login.desc-1') }}</div>
                    <ul class="points">
                        <li>
                            <svg-icon icon-class="check" style="vertical-align: top;" />
                            <span
                                >{{ $t('login.desc-2') }}
                                <span class="link">
                                    <a href="/">{{ $t('login.desc-7') }}!</a>
                                </span></span
                            >
                        </li>
                        <li>
                            <svg-icon icon-class="check" style="vertical-align: top;" />
                            <span>{{ $t('login.desc-3') }}</span>
                        </li>
                        <li>
                            <svg-icon icon-class="check" style="vertical-align: top;" />
                            <span
                                >{{ $t('login.desc-4') }}
                                <a class="link" rel="noreferrer" href="mailto:mp@cel.ro">{{
                                    $t('login.desc-5')
                                }}</a></span
                            >
                        </li>
                        <li>
                            <svg-icon icon-class="check" style="vertical-align: top;" />
                            <span>{{ $t('login.desc-6') }}</span>
                        </li>
                    </ul>
                    <!-- <div class="logos">
						<a v-for="(logo, index) in logos" :key="index" :href="logo.site" target="_blank">
							<img :src="logo.image" :alt="logo.alt">
						</a> -->
                    <!-- <img :src="logos" alt="Site-uri cel.ro"> -->
                    <!-- </div> -->
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import logoPath from '@/assets/images/logo_cel.png';
// import logos from '@/assets/images/logos.png'

import azerty from '@/assets/images/azerty.png';
import dcshop from '@/assets/images/dc-shop.png';
import cel from '@/assets/images/cel.png';
import domo from '@/assets/images/domo.png';
import marketonline from '@/assets/images/marketonline.png';
import mediadot from '@/assets/images/mediadot.png';

import ResizeMixin from '@/views/layout/mixin/ResizeHandler';
const LangSelect = () => import('@/components/LangSelect/text.vue');
import has from 'lodash.has';

export default {
    props: ['username_token', 'password_token'],
    name: 'login',
    metaInfo() {
        return {
            meta: [{ name: 'robots', content: 'noindex' }]
        };
    },
    components: {
        LangSelect
    },
    mixins: [ResizeMixin],
    data() {
        const validateUsername = (rule, value, callback) => {
            if (value.length < 1) {
                callback(new Error(this.$t('login.error-1')));
            } else {
                callback();
            }
        };
        const validatePass = (rule, value, callback) => {
            if (value.length < 1) {
                callback(new Error(this.$t('login.error-1')));
            } else {
                callback();
            }
        };
        return {
            loginForm: {
                username: '',
                password: '',
                remember: false
            },
            loginRules: {
                username: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateUsername
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validatePass
                    }
                ]
            },
            loading: false,
            logoPath,
            // logos,
            pwdType: 'password',
            logos: [
                {
                    site: 'http://cel.ro',
                    image: cel,
                    alt: 'cel.ro'
                },
                {
                    site: 'http://domo.ro',
                    image: domo,
                    alt: 'domo.ro'
                },
                {
                    site: 'http://marketonline.ro',
                    image: marketonline,
                    alt: 'marketonline.ro'
                },
                {
                    site: 'http://azerty.ro',
                    image: azerty,
                    alt: 'azerty.ro'
                },
                {
                    site: 'http://dc-shop.ro',
                    image: dcshop,
                    alt: 'dc-shop.ro'
                },
                {
                    site: 'http://mediadot.ro',
                    image: mediadot,
                    alt: 'mediadot.ro'
                }
            ]
        };
    },
    methods: {
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$store
                        .dispatch('user/Login', this.loginForm)
                        .then(() => {
                            this.loading = false;
                            if (has(localStorage, 'lastVisited')) {
                                try {
                                    const data = JSON.parse(localStorage.lastVisited);
                                    delete localStorage.lastVisited;
                                    this.$router
                                        .push({
                                            path: data.path
                                        })
                                        .catch(() => {});
                                    return true;
                                } catch (e) {}
                            }
                            this.$router
                                .push({
                                    path: '/dashboard/index'
                                })
                                .catch(() => {});
                        })
                        .catch((err) => {
                            this.loading = false;
                        });
                } else {
                    return false;
                }
            });
        },
        showPwd() {
            if (this.pwdType === 'password') {
                this.pwdType = '';
            } else {
                this.pwdType = 'password';
            }
        }
    },
    computed: {
        isDeviceMobile() {
            return this.$store.state.app.device === 'mobile';
        }
    },
    mounted() {
        if (typeof window.notificationInterval !== 'undefined') window.clearInterval(window.notificationInterval);
    },
    created() {
        if (typeof this.username_token !== 'undefined' && typeof this.password_token !== 'undefined') {
            this.loading = true;

            this.$store
                .dispatch('user/Login', {
                    username: this.username_token,
                    password: this.password_token
                })
                .then(() => {
                    this.loading = false;
                    if (has(localStorage, 'lastVisited')) {
                        try {
                            const data = JSON.parse(localStorage.lastVisited);
                            delete localStorage.lastVisited;
                            this.$router
                                .push({
                                    path: data.path
                                })
                                .catch(() => {});
                            return true;
                        } catch (e) {}
                    }
                    this.$router
                        .push({
                            path: '/'
                        })
                        .catch(() => {});
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style rel="stylesheet/scss" lang="scss">
@import './src/styles/mixin.scss';
@import './src/styles/modules/login.scss';
$bg: #f7f7f7;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
    @include relative;
    height: 100vh;
    background-color: $bg;

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
        -webkit-text-fill-color: #5a5e66 !important;
    }

    input {
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
    }

    .el-input {
        display: inline-block;
        height: 47px;
        width: 79%;
    }

    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;
    }

    .svg-container {
        color: #fb6e43;
        vertical-align: middle;
        width: 40px;
        display: inline-block;
        padding-left: 10px;
        font-size: 20px;
        border-right: 1px solid #dcdfe6;

        &_login {
            font-size: 20px;
        }
    }

    .title-no-border {
        font-size: 26px;
        font-weight: 400;
        margin: 0 auto 40px;
        text-align: center;
        font-weight: bold;
    }

    .login-form {
        position: absolute;
        left: 0;
        right: 0;
        width: 800px;
        max-width: 100%;
        // padding: 35px 35px 15px;
        margin: 120px auto;
        background-color: #ffffff;
        // border-top: 4px solid #ff601d;
        border-radius: 5px;
        -moz-box-shadow: 0 0 5px 4px rgba(207, 207, 207, 0.3);
        -webkit-box-shadow: 0 0 5px 4px rgba(207, 207, 207, 0.3);
        box-shadow: 0 0 5px 4px rgba(207, 207, 207, 0.3);

        &.mobile {
            width: 400px;
        }

        .el-form-item__error {
            line-height: 1.5;
            font-weight: bold;
        }

        .el-input input {
            color: black;
        }

        .el-form-item.is-required .el-form-item__content {
            background-color: #ffffff;
            border: 1px solid #f7f7f7;
            border-radius: 3px;
        }

        .form-wrapper {
            display: table;
            height: 100%;

            .form-contents {
                display: table-cell;
                width: 50%;
                vertical-align: middle;
                padding: 35px 35px 15px;
            }

            .description {
                display: table-cell;
                width: 50%;
                vertical-align: top;
                background-color: #fb6e43;
                color: #fff;
                padding: 35px 35px 120px;
                position: relative;
                border-radius: 0 5px 5px 0;

                .desc-title {
                    font-size: 24px;
                    margin-top: 45px;
                    font-weight: bold;
                }

                .link {
                    border: 2px solid #fca88c;
                    border-radius: 5px;
                    padding: 2px 6px;
                }

                ul {
                    list-style-type: none;
                    padding: 0;

                    li {
                        font-size: 15px;
                        padding-top: 25px;

                        &:first-child {
                            padding-top: 5px;
                        }

                        svg {
                            font-weight: bold;
                            padding-right: 10px;
                            display: inline-block;
                            vertical-align: top;
                            font-size: 24px;
                            top: -4px;
                            position: relative;
                        }

                        > span {
                            display: inline-block;
                            width: 85%;
                        }
                    }
                }
            }

            .logos {
                border-top: 1px solid #ff9270;
                /* margin-top: 100px; */
                padding: 20px 5px 0 5px;
                position: absolute;
                bottom: 15px;
                left: 20px;

                img {
                    padding: 5px 11px;
                }
            }
        }
    }

    .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #454545;
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: $dark_gray;
        cursor: pointer;
    }

    .thirdparty-button {
        position: absolute;
        right: 35px;
        bottom: 28px;
    }
}

body {
    background-color: #f7f7f7;
}

@media screen and (max-width: 400px) {
    .login-container .login-form {
        // margin-left: 5%;
        // margin-right: 5%;
        // max-width: 90%;
        margin: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}

@media screen and (max-width: 300px) {
    .login-container .el-input {
        width: 80%;
    }
}

.noAccountLogin,
.forgotPassword {
    text-align: center;
    padding: 0 0 5px;
    color: #6c6c6c;
    font-size: 13px;
}

.forgotPassword {
    padding-bottom: 3px;
}

.login-container .show-pwd {
    color: #889aa485;
}

.lang-select {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}
</style>
